import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import FroalaEditorComponent from "../froalaHtmlEditor/FroalaEditorComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { filterObjectsUptoCategory, timestamp } from "../../utils/services";
import { filterSelectTagIntro } from "./CommentLibraryHelper";

const froalaConfig = {
  imagePaste: false,
  spellCheck: true,
  heightMin: 145,
  heightMax: 170,
  toolbarButtons: {
    moreText: { buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"], align: "left", buttonsVisible: 3 },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const InlineIntroductoryText = ({ from, isActive, setIsActive, disableDnD, selectedID, accordOpen, AllCategoryList, categoryId, updateTemplate, setAllCategoryList }) => {
  const categoryData = AllCategoryList?.template?.categories?.find((category) => category.category_id === categoryId);
  const editor = useRef();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;
  const [introductory, setIntroductory] = useState({ innerHTML: categoryData?.introductory_text?.replace(/<[^>]+>\s*<\/[^>]+>/g, "") });
  const [introductoryDropdowns, setIntroductoryDropdowns] = useState(categoryData?.introductory_text_dropdowns || []);
  const [hasChanges, setHasChanges] = useState(false);
  const BorderRadius = (from) => {
    if (from?.split("_")[0] !== "SUBCATEGORY") {
      return "4px";
    }
  };
  const BorderBottomAccord = (from, isOpen) => {
    if (from?.split("_")[0] === "SUBCATEGORY" && isOpen) {
      return "none !important";
    } else if (from?.split("_")[0] === "SUBCATEGORY" && !isOpen) {
      return "1px solid #e7e7e9 ";
    }
  };
  const IsShadowing = (from) => {
    if (from?.split("_")[0] === "CATEGORY" && from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else if (from?.split("_")[0] === "CATEGORY" || from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else {
      return "none";
    }
  };

  const handleEditorChange = (name, content) => {
    setIntroductory({ innerHTML: content });
    setHasChanges(true);
  };

  const handleUpdateTemplateData = (name, value) => {
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    catList[categoryIndex].introductory_text = filterSelectTagIntro(value);
    catList[categoryIndex].updated_at = timestamp();
    setHasChanges(false);
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast");
    setAllCategoryList((prev) => {
      const newCategoryList = { ...AllCategoryList };
      newCategoryList.template.categories = catList;
      newCategoryList.template.categories.updated_at = timestamp();
      return newCategoryList;
    });
  };

  const handleChangeDropdown = (event) => {
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    const selectedValue = event.target.value;
    let textDropdowns = catList[categoryIndex].introductory_text_dropdowns;
    let textDropdownIndex = catList[categoryIndex].introductory_text_dropdowns?.findIndex((dropdown) => dropdown.dropdown_uuid === event.target.id);
    textDropdowns[textDropdownIndex] = { ...textDropdowns[textDropdownIndex], selected_value: selectedValue };
    catList[categoryIndex].introductory_text_dropdowns = textDropdowns;
    catList[categoryIndex].updated_at = new Date().toISOString();
    setIntroductoryDropdowns(textDropdowns);
    setHasChanges(false);
    const selectRegex = new RegExp(`<select[^>]*?id=["']${event.target.id}["'][\\s\\S]*?<\\/select>`, "g");
    const editorInstance = editor.current?.editor;
    let editorContent = editorInstance?.html?.get();
    editorContent = editorContent.replace(selectRegex, (selectMatch) => {
      const optionRegex = /<option([^>]*)>([^<]+)<\/option>/g;
      return selectMatch.replace(optionRegex, (optionMatch, attributes, value) => {
        const isSelected = value.trim() === selectedValue.trim();
        const updatedAttributes = attributes.replace(/selected/g, "").trim();
        return `<option ${updatedAttributes}${isSelected ? " selected" : ""}>${value}</option>`;
      });
    });
    editorInstance.html.set(editorContent);
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast");
    setAllCategoryList((prev) => {
      const newCategoryList = { ...AllCategoryList };
      newCategoryList.template.categories = catList;
      newCategoryList.template.categories.updated_at = timestamp();
      return newCategoryList;
    });
  };

  useEffect(() => {
    const editorInstance = editor.current?.editor;
    if (editorInstance) {
      const contentArea = editorInstance.$el.get(0);
      contentArea.addEventListener("change", handleChangeDropdown);
      return () => {
        contentArea.removeEventListener("change", handleChangeDropdown);
      };
    }
  }, [introductory, categoryData]);

  useEffect(() => {
    setAllCategoryList((prev) => {
      const newCategoryList = { ...AllCategoryList };
      newCategoryList.template.categories = AllCategoryList.template.categories;
      newCategoryList.template.categories.updated_at = timestamp();
      return newCategoryList;
    });
    document.addEventListener("changeValue", handleChangeDropdown);
    return () => {
      document.removeEventListener("changeValue", handleChangeDropdown);
    };
  }, [categoryData]);

  return (
    <>
      <div style={{ cursor: "auto", padding: 0 }}  >
        <div
          onClick={(event) => {
            event.stopPropagation();
            setIsActive(!isActive);
            disableDnD && disableDnD(!isActive, selectedID);
          }}
          style={{
            borderRadius: BorderRadius(from),
            boxShadow: IsShadowing(from),
            position: "relative",
            zIndex: isActive && "0",
            borderBottom: BorderBottomAccord(from, accordOpen),
            padding: "10px 0px 10px 10px",
            marginRight: "-10px",
            cursor: "pointer",
            height: "51px",
            alignContent: "center"
          }}
          className="accordion"
        >
          <div
            className="accordion__title"
            style={{
              color: isActive ? "#4282E2" : "#333333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "56px"
            }}
          >
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "8px",
              }}
            >
              Introductory Text
            </div>
            {isActive ? (
              <ExpandLessIcon sx={{ color: "#4282E2", ml: 4 }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#333333", ml: 4 }} />
            )}
          </div>
        </div>
        {isActive && (
          <div style={{ padding: "32px", borderRadius: "0 0 8px 8px" }}>
            <Grid container display="flex">
              <FroalaEditorComponent
                editor={editor}
                id={"introTextTE"}
                commentData={categoryData}
                onChange={handleEditorChange}
                editorData={introductory}
                froalaConfig={froalaConfig}
                onBlur={handleUpdateTemplateData}
              />
            </Grid>
          </div>
        )}
      </div>
    </>
  )
}

export default InlineIntroductoryText;
